import React from 'react';
import logo from './logo.svg';
import './App.css';
import { sha256 } from 'js-sha256'

function App() {
  const path = window.location.pathname.substring(1);
  const hashed = sha256(path);
  console.log("PATH,HASH", path, hashed);
  if (hashed == 'f9621e46c0474ed451ae45eb46cb47ac9de5704f74e4b93f4f76155994ca4a10') {
    return <img src={`https://codebaby.s3-us-west-1.amazonaws.com/${path}.jpg`} />
  }
  return (
    <div className="App">
      <b>You don't have the passcode</b>
    </div>
  );
}

export default App;
